import React from 'react';
import {
  Container,
  Grid,
  Typography,
} from '@material-ui/core'
import { Link, graphql } from 'gatsby'
import Layout from "../../components/layout"
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { createContent } from "../../common/Theme"
import FadeIn from "../../components/FadeIn"
import ProjectsLink from "../../components/projectsLink"
import ContactUs from "../../components/contactUs"

import '../../assets/styles/pages/services.scss'

const ConsultingPage = ({ data: { allMarkdownRemark: { edges } } }) => {
  const content = createContent();

  return (
    <Layout>
      <section className='services-hero-section' id='consulting-hero-section'>
        <Container classes={{root: content.content}}>
          <div className='services-hero-container'>
            <div className='services-hero-title'>
              <Typography className='text-tag' variant='body2' color='textSecondary'>What we do</Typography>
              <Typography variant='h1' color='textSecondary'>Consulting</Typography>
            </div>
            <div className='services-hero-image' id='consulting-hero-image' />
          </div>
        </Container>
      </section>
      <section>
        <Container classes={{root: content.content}}>
          <Grid container spacing={10}>
            <Grid item md={6} sm={6} xs={12}>
              <FadeIn>
                <Typography variant='h3'>Team Augmentation</Typography>
                <Typography>
                  We can help your team with a temporary boost in capacity or technical expertise
                  to complete your next project and achieve your goals.
                  Whether you need an extra hand on your team or want to outsource clear deliverables,
                  we are here to help make your next project a success.
                </Typography>
              </FadeIn>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <FadeIn>
                <Typography variant='h3'>Cloud Migration</Typography>
                <Typography>
                  Need advise on migrating your legacy applications to the cloud?
                  We provide guidance and advise you on the best cloud providers and services
                  for your business needs. By leveraging the power of the cloud you can benefit from
                  decentralised infrastructure and economies of scale which reduce your capital expenses.
                </Typography>
              </FadeIn>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <FadeIn>
                <Typography variant='h3'>Technical Training</Typography>
                <Typography>
                  We help in getting your team up to speed with the technical knowledge they need
                  to be a part of the digital future.
                  We offer tailor-made trainings in data awareness, SQL and basic programming concepts to help your non-technical
                  team members be more productive and make informed, data-driven decisions.
                </Typography>
              </FadeIn>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section id='related-cases-section'>
        <Container classes={{root: content.content}}>
          <Typography variant='h3' color='textSecondary'>Related projects</Typography>
          <Grid container spacing={6}>
            {
              edges.map(edge => (
                <Grid item md={6} key={edge.node.id}>
                  <ProjectsLink
                    slug={edge.node.frontmatter.slug}
                    title={edge.node.frontmatter.title}
                    image={edge.node.frontmatter.featuredImage}
                    categories={edge.node.frontmatter.categories}
                    light
                  />
                </Grid>
              ))
            }
          </Grid>
          <Typography variant='h3' color='textSecondary'>Other services</Typography>
          <Link to='/services/development' className='other-services-link'>
            <span>Development</span>
            <ArrowRightAltIcon />
          </Link>
          <Link to='/services/design' className='other-services-link'>
            <span>Design</span>
            <ArrowRightAltIcon />
          </Link>
        </Container>
      </section>

      <ContactUs mt />
    </Layout>
  )
}

export default ConsultingPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { slug: { in: ["/projects/clearfacts", "/projects/centralised-planning"] } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            slug
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
